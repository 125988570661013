<template>
  <div>
    <v-layout row align-center>
      <backButton />
      <h2 class="mb-4">
        {{ getHeaderForDetailPage }}
      </h2>
    </v-layout>
    <v-row no-gutters>
      <v-col xs="12">
        <v-form ref="form" id="editPageItemForm" lazy-validation>
          <template v-if="page && selectedItem">
            <v-row dense>
              <template v-for="(item, index) in selectedItem.Data">
                <pageElement
                  :key="index"
                  :item="item"
                  :websiteId="page.WebsiteId.$oid"
                  :pageId="page._id.$oid"
                  :objectName="index"
                  :colsWidth="item.colWidth ? item.colWidth : 12"
                  @update:saving="saving = $event"
                />
              </template>
            </v-row>
          </template>
          <template v-else-if="!$store.getters.error">
            <preloader></preloader>
          </template>
          <cancelConfirmButtons
            v-if="
              false == $route.fullPath.includes('detail') ||
              false == $route.params.isDetail
            "
            ref="cancelConfirm"
            @goBack="goBack"
            :confirmFunction="savePageContent"
            :loading="saving"
            :confirmLabel="$t('Save')"
          />
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      success: "",
      selectedItem: null,
      page: null,
      saving: false,
      objectValidationErrors: "",
      userIsAdministrator: this.$auth.userIsAdministrator(),
    };
  },
  created() {
    this.getPage();
  },
  watch: {
    "$route.params.id": function () {
      this.getPage();
    },
  },
  computed: {
    getHeaderForDetailPage() {
      let firstPart = "",
        secondPart = "";
      if (this.page && this.selectedItem) {
        secondPart = this.page.Name[this.currentLanguage];
        if (
          this.$route.params.itemId == 0 ||
          this.$route.params.itemId == undefined
        ) {
          firstPart = this.$t("Add new item to");
        } else if (this.$route.params.isCopy) {
          firstPart = this.$t("Copy item in");
        } else if (this.$route.params.isDetail) {
          firstPart = this.$t("Detail item in");
        } else {
          firstPart = this.$t("Edit item in");
        }
      }
      return firstPart + " " + secondPart;
    },
    currentLanguage: {
      get: function () {
        return this.$i18n.locale();
      },
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getPage() {
      this.$request.get("/page/" + this.$route.params.id, null, (res) => {
        if (res.success) {
          if (res.data.Content == null) {
            res.data.Content = [];
            res.data.Content[0] = {};
          }

          if (res.data.ContentDefinition == null) {
            res.data.ContentDefinition = {};
          }

          this.page = res.data;
          this.$store.dispatch("setSelectedWebsiteById", this.page.WebsiteId);
          if (!this.$route.params.itemId) {
            this.selectedItem = {
              PageId: this.page._id,
              WebsiteId: this.page.WebsiteId,
              Data: this.page.ContentDefinition,
            };
          } else {
            this.getContent();
          }
        } else {
          const toaster = {
            message: res.message,
            timeout: false,
            type: "ERROR",
            toastClassName: ["toaster-color-error", "toaster-layout"],
            bodyClassName: ["toaster-text-layout"],
            icon: "mdi-alert-octagon",
          };
          this.$store.dispatch("setToaster", toaster);
        }
      });
    },
    getContent() {
      this.$request.get(
        "/content/" + this.$route.params.itemId,
        null,
        (res) => {
          if (res.success) {
            this.selectedItem = res.data;
            this.objectValidationErrors =
              this.$helpers.pageItemDefinitionEqualToContentDefinition(
                this.selectedItem.Data,
                this.page.ContentDefinition
              );
            //needed for old data, if field got requird prop add new prop
            for (const key in this.selectedItem.Data) {
              if (
                this.selectedItem.Data[key].hasOwnProperty.call(
                  this.selectedItem.Data[key],
                  "Required"
                )
              ) {
                if (
                  this.selectedItem.Data[key].Required &&
                  (this.selectedItem.Data[key].Type == "text" ||
                    this.selectedItem.Data[key].Type == "richtext")
                ) {
                  this.selectedItem.Data[key]["FieldValidation"] = true;
                  this.selectedItem.Data[key]["FieldValidationFuntion"] =
                    "notEmptyStringRule";
                } else if (
                  this.selectedItem.Data[key].Required &&
                  this.selectedItem.Data[key].Type == "number"
                ) {
                  this.selectedItem.Data[key]["FieldValidation"] = true;
                  this.selectedItem.Data[key]["FieldValidationFuntion"] =
                    "notEmptyNumberRule";
                }
              }
            }
          } else {
            const toaster = {
              message: res.message,
              timeout: false,
              type: "ERROR",
              toastClassName: ["toaster-color-error", "toaster-layout"],
              bodyClassName: ["toaster-text-layout"],
              icon: "mdi-alert-octagon",
            };
            this.$store.dispatch("setToaster", toaster);
          }
        }
      );
    },
    savePageContent() {
      let formIsValid = this.$refs.form.validate();
      if (formIsValid) {
        this.saving = true;
        this.selectedItem.DisplayLabel = this.$helpers.getDisplayLabel(
          this.selectedItem,
          this.page.DisplayLabelCustomFormat
        );
        const langs = this.$config.contentLanguages;
        if (!this.selectedItem.DisplayLabels) {
          this.selectedItem.DisplayLabels = {};
        }
        langs.forEach((lang) => {
          this.selectedItem.DisplayLabels[lang] = this.$helpers.getDisplayLabel(
            this.selectedItem,
            this.page.DisplayLabelCustomFormat,
            lang
          );
        });
        if (this.$route.params.isCopy) {
          this.$delete(this.selectedItem, "_id");
        }
        this.$request.put("/content", this.selectedItem, (res) => {
          this.saving = false;
          if (res.success) {
            if (
              this.page.SynchronizationPutUrl &&
              this.page.SynchronizationPutUrl.length > 0
            ) {
              this.$request.put(
                this.page.SynchronizationPutUrl,
                this.selectedItem,
                (res) => {
                  if (!res.success) {
                    const toaster = {
                      message: res.message,
                      timeout: false,
                      type: "ERROR",
                      toastClassName: ["toaster-color-error", "toaster-layout"],
                      bodyClassName: ["toaster-text-layout"],
                      icon: "mdi-alert-octagon",
                    };
                    this.$store.dispatch("setToaster", toaster);
                  } else {
                    const toaster = {
                      message: res.message,
                      timeout: false,
                      type: "SUCCESS",
                      toastClassName: [
                        "toaster-color-success",
                        "toaster-layout",
                      ],
                      bodyClassName: ["toaster-text-layout"],
                      icon: "mdi-check-circle",
                    };
                    this.$store.dispatch("setToaster", toaster);
                  }
                }
              );
            }
            this.$router.go(-1);
          } else {
            const toaster = {
              message: res.message,
              timeout: false,
              type: "ERROR",
              toastClassName: ["toaster-color-error", "toaster-layout"],
              bodyClassName: ["toaster-text-layout"],
              icon: "mdi-alert-octagon",
            };
            this.$store.dispatch("setToaster", toaster);
          }
        });
      } else {
        const toaster = {
          message: this.$i18n.translate(
            "Please make sure all text fields have a value"
          ),
          timeout: false,
          type: "ERROR",
          toastClassName: ["toaster-color-error", "toaster-layout"],
          bodyClassName: ["toaster-text-layout"],
          icon: "mdi-alert-octagon",
        };
        this.$store.dispatch("setToaster", toaster);
      }
    },
  },
};
</script>

<style>
</style>
